import React from "react";
import styled from "styled-components";
import Head from "../components/head";
import Header from "../components/header";
import "./../components/layout.css";
import Footer from "../components/footer";
import CardList from "../components/Card/CardList";
import flowOfTime from "./../images/flow-of-time.mp4";
import { device } from "./../Helper/device";

const IndexWrap = styled.main``;

const TopBox = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VideoStyle = styled.video`
  position: relative;
  width: 100%;
  opacity: 0.7;
`;

const VideoTextBox = styled.div`
  position: absolute;
  width: 100%;
  z-index: 1;
`;

const VideoText = styled.p`
  text-align: center;
  z-index: 1;
  color: white;
  font-size: 16px;
  font-weight: bold;
  @media ${device.desktop} {
    font-size: 30px;
  }
`;

const FadeInText = styled.p`
  display: block;
  text-align: center;
  z-index: 1;
  color: white;
  font-size: 12px;
  font-weight: bold;
  animation-name: fadeInAnime;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  @media ${device.desktop} {
    font-size: 20px;
  }
  @keyframes fadeInAnime {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`;

const CardListWrap = styled.div`
  margin: 20px 0;
`;

const IndexPage = () => {
  return (
    <>
      <Head />
      {/* ヘッダー */}
      <Header />
      <IndexWrap>
        <title>株式会社八坂堂</title>
        <TopBox>
          <VideoTextBox>
            <VideoText>From a New Angle</VideoText>
            <VideoText>〜新しい角度から〜</VideoText>
            <FadeInText>
              若者からお年寄りまでインターネットが
              <FadeInText> 当たり前になったネット社会だからこそ</FadeInText>
              <FadeInText>
                店舗の経営に加えてネット集客・ECサイトなどの集客を始める
              </FadeInText>
              必要があります。
            </FadeInText>
          </VideoTextBox>
          <VideoStyle
            src={flowOfTime}
            autoPlay
            muted
            loop
            playsInline
          ></VideoStyle>
        </TopBox>
        {/* サブメニュー
        <SubMenu /> */}
        <CardListWrap>
          <CardList />
        </CardListWrap>
        {/* フッター */}
        <Footer />
      </IndexWrap>
    </>
  );
};

export default IndexPage;
